import { useEffect, useState } from "react";
import { socket } from "../socket";
import CodeBlock from "@mittwald/flow-react-components/CodeBlock";
import { CustomLoadingSpinner } from "@hueskehilker/extension-lib-frontend";

export const ErrorLog = (props: {
  appInstallationId: string;
  projectId: string;
  extensionInstanceId: string;
}) => {
  const [isReady, setIsReady] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    console.log("connecting");
    socket.connect();

    const onLog = (event: string) => {
      setLogs((prev) => [event, ...prev.slice(0, 99)]);
    };

    socket.on("connect", () => {
      console.log("connected");

      socket.emit("subscribe", {
        projectId: props.projectId,
        appInstallationId: props.appInstallationId,
        extensionInstanceId: props.extensionInstanceId,
      });
    });

    socket.on("sshConnected", () => {
      setIsReady(true);
    });

    socket.on("log", onLog);

    return () => {
      socket.off("log", onLog);
      socket.disconnect();
      console.log("disconnected");
    };
  }, []);

  return (
    <>
      {isReady ? (
        <>
          <CustomLoadingSpinner text="Empfange Live Error-Log" />
          <CodeBlock
            code={`...${logs.length == 0 ? "\n" : "\n\n"}${logs.join("\n")}`}
          />
        </>
      ) : (
        <CustomLoadingSpinner text="Verbinde mit Server" />
      )}
    </>
  );
};
