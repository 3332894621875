import Heading from "@mittwald/flow-react-components/Heading";
import Section from "@mittwald/flow-react-components/Section";
import { useRouteError } from "react-router";
import LayoutCard from "@mittwald/flow-react-components/LayoutCard";
import {
  DefaultErrorComponent,
  ErrorDeveloperDetails,
  ErrorMap,
  Footer,
} from "@hueskehilker/extension-lib-frontend";

export const ErrorElement = (props: { errorMap: ErrorMap }) => {
  const error = useRouteError();

  if (!(error instanceof Error)) {
    throw new Error("error is no Error");
  }

  const ErrorComponent =
    props.errorMap[error?.constructor?.name] ??
    (() => <DefaultErrorComponent />);

  return (
    <>
      <LayoutCard>
        <Section>
          <Heading level={1}>Ein Fehler ist aufgetreten</Heading>

          {ErrorComponent(error)}

          <ErrorDeveloperDetails />
        </Section>
      </LayoutCard>
      <LayoutCard>
        <Footer />
      </LayoutCard>
    </>
  );
};
