import Skeleton from "@mittwald/flow-react-components/Skeleton";

export const OPcacheStatsSkeleton = () => {
  return (
    <>
      <Skeleton height={"50px"} />
      <Skeleton height={"50px"} />
    </>
  );
};
