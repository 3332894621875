import { FormInputs } from "./components/AppInstallationPHPSettings.tsx";
import { components } from "./openapi/generated-types.ts";

export const getRecommendedAppPHPSettings = (
  app: components["schemas"]["AppInstallation"],
): Record<string, Partial<FormInputs>> => {
  return {
    CMS: {
      opcacheEnabled: "on",
      displayErrors: "off",
      logErrors: "on",
      errorLog: `/tmp/php_errors_${app.shortId}.log`,
      errorReporting: "E_ALL & ~E_NOTICE & ~E_DEPRECATED & ~E_STRICT",
      maxExecutionTime: 300,
      maxInputTime: 300,
      postMaxSize: 256,
      uploadMaxFilesize: 256,
      memoryLimit: 512,
      maxInputVars: 5000,
    },
    Shop: {
      opcacheEnabled: "on",
      displayErrors: "off",
      logErrors: "on",
      errorLog: `/tmp/php_errors_${app.shortId}.log`,
      errorReporting: "E_ALL & ~E_NOTICE & ~E_DEPRECATED & ~E_STRICT",
      maxExecutionTime: 600,
      maxInputTime: 600,
      postMaxSize: 256,
      uploadMaxFilesize: 256,
      memoryLimit: 2048,
      maxInputVars: 10000,
    },
  };
};
