import { clearAPCu } from "../openapi/api-client.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useClearAPCu = (
  projectId: string,
  appInstallationId: string,
  extensionInstanceId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return clearAPCu({
        header: { extensionInstanceId },
        path: { projectId, appInstallationId },
      });
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "appInstallationPHPStatistics",
            { projectId, appInstallationId },
          ],
        }),
      ]);
    },
  });
};
