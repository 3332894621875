import Action from "@mittwald/flow-react-components/Action";
import Text from "@mittwald/flow-react-components/Text";
import Content from "@mittwald/flow-react-components/Content";
import Heading from "@mittwald/flow-react-components/Heading";
import Icon from "@mittwald/flow-react-components/Icon";
import { IconSettings } from "@mittwald/flow-react-components/Icons";
import Modal from "@mittwald/flow-react-components/Modal";
import Section from "@mittwald/flow-react-components/Section";
import ColumnLayout from "@mittwald/flow-react-components/ColumnLayout";
import Label from "@mittwald/flow-react-components/Label";
import ActionGroup from "@mittwald/flow-react-components/ActionGroup";
import Button from "@mittwald/flow-react-components/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Form,
  typedField,
} from "@mittwald/flow-react-components/react-hook-form";
import { useState } from "react";
import { useOverlayController } from "@mittwald/flow-react-components/controller";
import { useProjectPHPSettings } from "../hooks/useProjectPHPSettings";
import { useApplyProjectPHPSettings } from "../hooks/useApplyProjectPHPSettings";
import { SavingProjectIniError } from "../errors/SavingProjectIniError";
import NumberField from "@mittwald/flow-react-components/NumberField";

interface FormInputs {
  apcuShmSize: number;
}

export const UpdateAPCuModal = (props: {
  projectId: string;
  extensionInstanceId: string;
}) => {
  const [errorOnSubmit, setErrorOnSubmit] = useState<Error | undefined>(
    undefined,
  );

  if (errorOnSubmit) {
    throw errorOnSubmit;
  }

  const controller = useOverlayController("Modal");

  controller.addOnClose(() => {
    form.reset();
  });

  const projectPHPSettings = useProjectPHPSettings(
    props.projectId,
    props.extensionInstanceId,
  );

  const form = useForm<FormInputs>({
    defaultValues: {
      apcuShmSize: projectPHPSettings.data.apcuShmSize ?? NaN,
    },
  });

  const applyProjectPHPSettingsMutation = useApplyProjectPHPSettings(
    props.projectId,
    props.extensionInstanceId,
  );

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const result = await applyProjectPHPSettingsMutation.mutateAsync({
      ...projectPHPSettings.data,
      apcuShmSize: !isNaN(data.apcuShmSize) ? data.apcuShmSize : undefined,
    });

    if (result.error) {
      setErrorOnSubmit(new SavingProjectIniError(`${result.error.message}`));
    }

    form.reset({ apcuShmSize: data.apcuShmSize });

    controller.close();
  };

  const Field = typedField(form);

  return (
    <Modal controller={controller} size="m">
      <Form form={form} onSubmit={onSubmit}>
        <Heading>
          <Icon>
            <IconSettings />
          </Icon>
          <Text>APCu-Einstellungen</Text>
        </Heading>
        <Content>
          <Section>
            <Text>
              Änderungen, die du hier machst, wirken sich auf das gesamte
              Projekt bzw. alle Apps aus.
            </Text>

            <ColumnLayout m={[1, 1]}>
              <Field name={"apcuShmSize"}>
                <NumberField
                  isRequired
                  isDisabled={applyProjectPHPSettingsMutation.isPending}
                  formatOptions={{
                    style: "unit",
                    unit: "megabyte",
                  }}
                  step={64}
                >
                  <Label>Maximaler Speicherverbrauch (apc.shm_size)</Label>
                </NumberField>
              </Field>
            </ColumnLayout>
          </Section>
        </Content>
        <ActionGroup>
          <Button
            color="accent"
            type="submit"
            isPending={applyProjectPHPSettingsMutation.isPending}
            isSucceeded={applyProjectPHPSettingsMutation.isSuccess}
          >
            Einstellungen speichern
          </Button>
          <Action
            action={() => {
              controller.close();
            }}
          >
            <Button color="secondary" variant="soft">
              Abbrechen
            </Button>
          </Action>
        </ActionGroup>
      </Form>
    </Modal>
  );
};
