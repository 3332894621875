import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "@mittwald/flow-react-components/all.css";
import "@hueskehilker/extension-lib-frontend/dist/index.css";
import { RouterProvider } from "react-router";
import { routes } from "./routes.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlagProvider, IConfig } from "@unleash/proxy-client-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
    },
  },
});

const unleashConfig: IConfig = {
  url: "https://phpsettingseditor.extensions.hueskehilker.de/feature-flags/proxy", // Your local instance Unleash API URL
  clientKey: "RNER0DmqBdslbX8d81Vz", // Your client-side API token
  refreshInterval: 60, // How often (in seconds) the client should poll the proxy for updates
  appName: "production", // The name of your application. It's only used for identifying your application
};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <FlagProvider config={unleashConfig}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={routes} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </FlagProvider>
  </StrictMode>,
);
