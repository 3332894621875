import ColumnLayout from "@mittwald/flow-react-components/ColumnLayout";
import Skeleton from "@mittwald/flow-react-components/Skeleton";

export const AppInstallationSelectionSkeleton = () => {
  return (
    <ColumnLayout>
      <Skeleton style={{ height: "117px" }} />
      <Skeleton style={{ height: "117px" }} />
      <Skeleton style={{ height: "117px" }} />
    </ColumnLayout>
  );
};
