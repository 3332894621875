import { useSuspenseQuery } from "@tanstack/react-query";
import { getAppInstallationPHPStatistics } from "../openapi/api-client.ts";
import { LoadProjectPHPStatisticsError } from "../errors/LoadProjectPHPStatisticsError.ts";

export const useAppInstallationPHPStatistics = (
  projectId: string,
  appInstallationId: string,
  extensionInstanceId: string,
) =>
  useSuspenseQuery({
    queryKey: [
      "appInstallationPHPStatistics",
      { projectId, appInstallationId },
    ],
    queryFn: async () => {
      const response = await getAppInstallationPHPStatistics({
        path: { projectId, appInstallationId },
        header: { extensionInstanceId },
      });

      if (!response.data) {
        throw new LoadProjectPHPStatisticsError(
          `error while fetching project php statistics: ${response.error.message}`,
        );
      }

      return response.data;
    },
  });
