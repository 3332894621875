import { applyProjectPHPSettings } from "../openapi/api-client.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { operations } from "../openapi/generated-types.ts";

export const useApplyProjectPHPSettings = (
  projectId: string,
  extensionInstanceId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      body: operations["project.apply-php-settings"]["requestBody"]["content"]["application/json"],
    ) => {
      return applyProjectPHPSettings(body, {
        header: { extensionInstanceId },
        path: { projectId },
      });
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["projectPHPSettings", { projectId }],
        }),
        queryClient.invalidateQueries({
          queryKey: ["appInstallationPHPStatistics", { projectId }],
        }),
      ]);
    },
  });
};
