import { useProject } from "../hooks/useProject.ts";
import RadioGroup, {
  RadioButton,
} from "@mittwald/flow-react-components/RadioGroup";
import Label from "@mittwald/flow-react-components/Label";
import Content from "@mittwald/flow-react-components/Content";
import Alert from "@mittwald/flow-react-components/Alert";
import Heading from "@mittwald/flow-react-components/Heading";
import Link from "@mittwald/flow-react-components/Link";
import { useEffect, useState } from "react";

export const AppInstallationSelection = (props: {
  projectId: string;
  extensionInstanceId: string;
  appInstallationSelected: (appInstallationId: string) => void;
}) => {
  const project = useProject(props.projectId, props.extensionInstanceId);
  const [selectedAppInstallation, setSelectedAppInstallation] = useState<
    string | undefined
  >();

  const projectsWithPHP = project.data.appInstallations.filter(
    (a) => !!a.phpVersion,
  );

  useEffect(() => {
    if (projectsWithPHP.length == 1) {
      setSelectedAppInstallation(projectsWithPHP[0].id);
    }
  }, [project, props.appInstallationSelected]);

  useEffect(() => {
    if (selectedAppInstallation) {
      props.appInstallationSelected(selectedAppInstallation);
    }
  }, [selectedAppInstallation]);

  return (
    <>
      {projectsWithPHP.length == 0 ? (
        <Alert>
          <Heading>Keine Apps gefunden</Heading>
          <Content>
            In deinem Projekt sind keine Apps mit PHP installiert. Bitte
            installiere eine App, um die PHP-Einstellungen konfigurieren zu
            können.
            <Link
              href={`https://studio.mittwald.de/app/projects/${project.data.id}/apps`}
            >
              App installieren
            </Link>
          </Content>
        </Alert>
      ) : (
        <RadioGroup
          onChange={setSelectedAppInstallation}
          value={selectedAppInstallation}
        >
          <Label>App-Installation auswählen</Label>
          {project.data.appInstallations.map((a) => {
            return (
              <RadioButton key={a.id} value={a.id} isDisabled={!a.phpVersion}>
                {a.description}
              </RadioButton>
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};
