import { resetOPcache } from "../openapi/api-client.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useResetOPcache = (
  projectId: string,
  appInstallationId: string,
  extensionInstanceId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return resetOPcache({
        header: { extensionInstanceId },
        path: { projectId, appInstallationId },
      });
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "appInstallationPHPStatistics",
            { projectId, appInstallationId },
          ],
        }),
      ]);
    },
  });
};
