import { applyAppInstallationPHPSettings } from "../openapi/api-client.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { operations } from "../openapi/generated-types.ts";

export const useApplyAppInstallationPHPSettings = (
  projectId: string,
  appInstallationId: string,
  extensionInstanceId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      body: operations["appinstallation.apply-php-settings"]["requestBody"]["content"]["application/json"],
    ) => {
      return applyAppInstallationPHPSettings(body, {
        header: { extensionInstanceId },
        path: { projectId, appInstallationId },
      });
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "appInstallationPHPSettings",
            { projectId, appInstallationId },
          ],
        }),
        queryClient.invalidateQueries({
          queryKey: ["appInstallationPHPStatistics", { projectId }],
        }),
      ]);
    },
  });
};
