import CodeBlock from "@mittwald/flow-react-components/CodeBlock";
import Link from "@mittwald/flow-react-components/Link";
import Text from "@mittwald/flow-react-components/Text";
import InlineCode from "@mittwald/flow-react-components/InlineCode";
import {
  defaultErrorComponentMap,
  ErrorMap,
} from "@hueskehilker/extension-lib-frontend";

export const errorMap: ErrorMap = {
  ...defaultErrorComponentMap,
  SavingAppInstallationIniError: (error: Error) => (
    <>
      <Text>
        Wir konnten deine Konfiguration nicht speichern. Folgende Fehlermeldung
        wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Bitte überprüfe, ob du aus dieser Fehlermeldung das Problem ableiten
        kannst. Falls nicht, melde dich mit den Fehlerdetails bei unserem
        Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Speichern der App-PHP-Einstellungen",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Speichern der App-PHP-Einstellungen aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
  SavingProjectIniError: (error: Error) => (
    <>
      <Text>
        Wir konnten deine Konfiguration nicht speichern. Folgende Fehlermeldung
        wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Bitte überprüfe, ob du aus dieser Fehlermeldung das Problem ableiten
        kannst. Falls nicht, melde dich mit den Fehlerdetails bei unserem
        Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Speichern der Projekt-PHP-Einstellungen",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Speichern der Projekt-PHP-Einstellungen aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
  LoadProjectError: (error: Error) => (
    <>
      <Text>
        Wir konnten dein Projekt nicht aus dem mStudio abrufen. Folgende
        Fehlermeldung wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Möglicherweise handelt es sich dabei nur um ein temporäres Problem.
        Probiere es einfach in einigen Augenblicken nochmal.
      </Text>
      <Link href={window.location.href}>Nochmal versuchen</Link>
      <Text>
        Wenn sich das Problem nicht von selbst löst, melde dich mit den
        Fehlerdetails bei unserem Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Laden des Projektes aus dem mStudio",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Laden des Projektes aus dem mStudio aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
  LoadAppPHPSettingsError: (error: Error) => (
    <>
      <Text>
        Wir konnten die PHP-Einstellungen deiner App nicht laden. Folgende
        Fehlermeldung wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Häufig ist das Problem eine ungültige <InlineCode>.user.ini</InlineCode>
        -Datei in deinem App-Verzeichnis. Bitte überprüfe die Syntax und lösche
        die Datei ggf. einfach.
      </Text>
      <Link href={window.location.href}>Nochmal versuchen</Link>
      <Text>
        Wenn sich das Problem nicht lösen lässt, melde dich mit den
        Fehlerdetails bei unserem Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Laden der PHP-Einstellungen der App",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Laden der PHP-Einstellungen der App aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
  LoadProjectPHPSettingsError: (error: Error) => (
    <>
      <Text>
        Wir konnten die PHP-Einstellungen deines Projektes nicht laden. Folgende
        Fehlermeldung wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Häufig ist das Problem eine ungültige <InlineCode>php.ini</InlineCode>{" "}
        in deinem Projekt. Bitte überprüfe die Syntax und leere die Datei ggf.
        einfach. Du findest die Datei unter dem Pfad{" "}
        <InlineCode>/etc/php/php.ini</InlineCode>
      </Text>
      <Link href={window.location.href}>Nochmal versuchen</Link>
      <Text>
        Wenn sich das Problem nicht lösen lässt, melde dich mit den
        Fehlerdetails bei unserem Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Laden der PHP-Einstellungen des Projektes",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Laden der PHP-Einstellungen des Projektes aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
  LoadProjectPHPStatisticsError: (error: Error) => (
    <>
      <Text>
        Wir konnten die Cache-Statistiken deiner App nicht laden. Folgende
        Fehlermeldung wurde vom Server geliefert:
      </Text>
      <CodeBlock code={error.message} copyable />
      <Text>
        Bitte überprüfe, ob du aus dieser Fehlermeldung das Problem ableiten
        kannst. Falls nicht, melde dich mit den Fehlerdetails bei unserem
        Support, dann unterstützen wir dich gerne.
      </Text>
      <Link
        href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent(
          "Fehler beim Laden der Cache-Statistiken der App",
        )}&body=${encodeURIComponent(
          `Folgender Fehler ist beim Laden der Cache-Statistiken der App aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`,
        )}`}
      >
        support@hueskehilker.de
      </Link>
    </>
  ),
};
