import { createBrowserRouter } from "react-router";
import App from "./App.tsx";
import { Editor } from "./Pages/Editor.tsx";
import { ErrorElement } from "./components/ErrorElement.tsx";
import { errorMap } from "./errorMap.tsx";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorElement errorMap={errorMap} />,
    children: [
      {
        path: "/",
        element: <Editor />,
      },
    ],
  },
]);
