import Heading from "@mittwald/flow-react-components/Heading";
import { useAppInstallationPHPStatistics } from "../hooks/useAppInstallationPHPStatistics.ts";
import ProgressBar from "@mittwald/flow-react-components/ProgressBar";
import Text from "@mittwald/flow-react-components/Text";
import Header from "@mittwald/flow-react-components/Header";
import ContextualHelp, {
  ContextualHelpTrigger,
} from "@mittwald/flow-react-components/ContextualHelp";
import Button from "@mittwald/flow-react-components/Button";
import Link from "@mittwald/flow-react-components/Link";
import Modal, { ModalTrigger } from "@mittwald/flow-react-components/Modal";
import { IconDelete } from "@mittwald/flow-react-components/Icons";
import Content from "@mittwald/flow-react-components/Content";
import Section from "@mittwald/flow-react-components/Section";
import ActionGroup from "@mittwald/flow-react-components/ActionGroup";
import Action from "@mittwald/flow-react-components/Action";
import Label from "@mittwald/flow-react-components/Label";
import { useResetOPcache } from "../hooks/useResetOPcache.ts";
import { UpdateOPcacheModal } from "./UpdateOPcacheModal.tsx";
import { useFlag } from "@unleash/proxy-client-react";

export const OPcacheStats = (props: {
  projectId: string;
  appInstallationId: string;
  extensionInstanceId: string;
}) => {
  const phpStats = useAppInstallationPHPStatistics(
    props.projectId,
    props.appInstallationId,
    props.extensionInstanceId,
  );

  const resetOPCacheMutation = useResetOPcache(
    props.projectId,
    props.appInstallationId,
    props.extensionInstanceId,
  );

  const showProjectSettings = useFlag("project_settings");

  return (
    <>
      <Header>
        <Heading level={3}>
          OPcache
          <ContextualHelpTrigger>
            <Button />
            <ContextualHelp>
              <Heading>OPcache</Heading>
              <Text>
                OPcache steigert die PHP-Performance, indem es den
                vorkompilierten Bytecode im Arbeitsspeicher ablegt. Dadurch
                entfällt das erneute Laden und Parsen der Skripte bei jedem
                einzelnen Request.
              </Text>
              <Link
                href="https://www.php.net/manual/de/book.opcache.php"
                target="_blank"
              >
                Mehr erfahren
              </Link>
            </ContextualHelp>
          </ContextualHelpTrigger>
        </Heading>
        <Action action={() => resetOPCacheMutation.mutateAsync()}>
          <Modal size="s" slot="actionConfirm">
            <Heading>
              <IconDelete />
              Möchtest du den OPcache wirklich leeren?
            </Heading>
            <Content>
              <Section>
                <Text>
                  Das Leeren des OPcache sorgt initial für einen verlängerten
                  Seitenaufruf.
                </Text>
              </Section>
            </Content>
            <ActionGroup>
              <Button color="danger">OPcache leeren</Button>
              <Button variant="soft" color="secondary">
                Abbrechen
              </Button>
            </ActionGroup>
          </Modal>
          <Button color="danger" variant="soft">
            OPcache leeren
          </Button>
        </Action>
        {showProjectSettings && (
          <Action>
            <ModalTrigger>
              <Button color="secondary">Einstellungen</Button>
              <UpdateOPcacheModal
                extensionInstanceId={props.extensionInstanceId}
                projectId={props.projectId}
              />
            </ModalTrigger>
          </Action>
        )}
      </Header>
      <ProgressBar
        value={phpStats.data.opcache?.usage ?? 0}
        maxValue={phpStats.data.opcache?.size ?? 0}
        showMaxValue
        formatOptions={{ style: "unit", unit: "megabyte" }}
      >
        <Label>Aktuelle OPcache-Auslastung</Label>
      </ProgressBar>
    </>
  );
};
